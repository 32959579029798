import React from 'react'
import Header from '../components/landing/header'

export default function Privacy () {
  return (
    <div className='bg-gradient-to-b from-[#060525] via-[#02096E] via-[#030756]  via-[#130412]  via-[#050534] via-[#06041F] to-[#060317] overflow-hidden min-h-screen'>
      <Header />
      <div className='container mx-auto text-white container px-5 mt-10'>
        <h1 className='text-3xl mb-2'>Privacy Policy</h1>
        <p className='mb-10'>
          Last Updated: 29<sup>th</sup> November 2022
        </p>

        <p className='mb-6'>
          Liyana AI (“Liyana”, “us”, “we”, or “our”) respects the privacy of its
          users (“users” or “you”). This Privacy Policy (“Policy”) explains how
          we collect, use, and share information we collect from you when you
          use our AI powered writing assistant powered by Open AI’s GPT-3 (the
          “Service”). When using the Service, we may collect certain information
          about you and your use of the Service.
        </p>
        <p className='mb-6'>
          This information may include:
          <ul className='ml-3 mt-2'>
            <li>
              • Your name and contact information (such as email address);
            </li>
            <li>• Your device’s IP address; </li>
            <li>• Your device’s operating system; </li>
            <li>• Your device’s hardware information; </li>
            <li>• Your location; </li>
            <li>• Your activity on the Service; </li>
            <li>• Other information related to your use of the Service. </li>
          </ul>
        </p>
        <p className='mb-6'>
          We may use the information we collect for a variety of purposes,
          including:
          <ul className='ml-3 mt-2'>
            <li>• To provide and improve the Service;</li>
            <li>• To contact you with Service-related communications; </li>
            <li>• To respond to your inquiries;</li>
            <li>• To comply with any applicable laws and regulations.</li>
          </ul>
        </p>
        <p className='mb-6'>
          We may share your information with third parties, such as service
          providers, in order to provide the Service.
        </p>
        <p className='mb-6'>
          We will only share your information in accordance with applicable law.
        </p>
        <p className='mb-6'>
          We may transfer your information to other countries in order to
          provide the Service. Any such transfer will comply with applicable
          law.
        </p>
        <p className='mb-6'>
          We take reasonable steps to protect the security of your information.
          However, we cannot guarantee the security of your information.
        </p>
        <p className='mb-6'>
          We may update this Policy from time to time. Any changes we make to
          this Policy will be reflected in the “Last Updated” date above.
        </p>
        <p className='mb-6'>
          If you have any questions or concerns about this Policy, please
          contact us at info@liyana.ai
        </p>
      </div>
    </div>
  )
}
